<template>
  <section>
    <div v-for="(procedure, index) in surgeryProcedures" :key="procedure?.id ?? procedure?.temp_id">
      <SurgeryDataComponent
        class="surgery-data"
        :index="index"
        :surgeryInformationId="surgeryInformationId"
        :surgeryProcedure="procedure"
        :participants="procedure.participants"
        :copyTeam="index ? copyTeam : null"
        :beneficiaries="beneficiaries"
        :isLoading="isLoading"
        :manageMatmed="index === surgeryProcedures.length - 1"
      />
    </div>
  </section>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'SurgeryData',
  components: {
    SurgeryDataComponent: () =>
      import('@/components/SurgeryCenter/SurgeryInformation/Modal/SurgeryData')
  },
  props: {
    procedures: {
      type: Array,
      default: () => []
    },
    surgeryInformationId: String
  },
  data: () => ({
    clinic: getCurrentClinic(),
    surgeryProcedures: [],
    beneficiaries: [],
    isLoading: false
  }),
  async created() {
    await this.getBeneficiaries()
  },
  methods: {
    async getBeneficiaries() {
      this.isLoading = true
      try {
        const { data } = await this.api.getBeneficiariesByClinicId(
          this.clinic.id
        )
        this.beneficiaries = data
      } catch (error) {
        this.$toast.error('Erro ao carregar favorecidos')
      } finally {
        this.isLoading = false
      }
    },
    async getSurgeryInformationProcedures() {
      this.isLoading = true
      try {
        const { data } = await this.api.getSurgeryInformationProcedures(this.surgeryInformationId)
        this.surgeryProcedures = this.procedures.map(el => {
          const surgeryInfo = data.find(item => item.item_id === el.item_id)
          surgeryInfo && (el = { ...el, ...surgeryInfo })
          if(!el?.item?.type) {
            console.log(el);
            el.item.type = 'SURGICAL'
          }
          return el
        })
      } catch (error) {
        this.$toast.error('Erro ao carregar informações da cirurgia')
      } finally {
        this.isLoading = false
      }
    },
    copyTeam(procedure) {
      const index = this.surgeryProcedures.findIndex(
        item => item.item_id === procedure.item_id
      )

      if (index === -1) {
        this.$toast.error('Erro ao copiar equipe')
        return
      }
      if (index === 0) return
      const copyParticipants = JSON.parse(
        JSON.stringify(this.surgeryProcedures[index - 1].participants)
      )
      this.$set(
        this.surgeryProcedures[index],
        'participants',
        copyParticipants
      )
      this.$toast.success('Equipe copiada com sucesso')
    }
  },
  watch: {
    surgeryInformationId: {
      async handler(value) {
        if (!value) return
        await this.getSurgeryInformationProcedures()
      },
      immediate: true
    },
    surgeryProcedures: {
      async handler(value) {
        if (!value.length) return
        this.$emit('update', this.surgeryProcedures)
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
.surgery-data:last-child {
  hr {
    display: none;
  }
}
</style>
